import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
//import { Link } from 'react-router-dom';
import './App.css';
import Whale from './assets/images/Whale';
import postsData from './content.json';

function App() {
  const [color, setColor] = useState('#000000');

  useEffect(() => {
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 15)];
      }
      return color;
    };

    setColor(getRandomColor());
  }, []);

  const formatDate = (dateStr, postId) => {
    const [year, month, day] = dateStr.split("-");
    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date: ${dateStr} in post with ID: ${postId}`);
    }
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="App" style={{ '--loading-color': color }}>
      <Helmet>
        <title>Seven Whales</title>
        <meta name="description" content="A place to put things — maybe a blog, maybe not"></meta>
      </Helmet>
      <div className="fade-overlay"></div>
      <div className="loading-bar"></div>
      <header className="App-header">
        <div className="logo-text">
          <Whale color={color} width="40" height="40" />
          <h1>seven whales</h1>
        </div>
      </header>
      <main className="App-body">
        {postsData.length === 0 ? (
          <div className="empty-content">
            <h3>Nothing yet...</h3>
            <p>Creative stuff coming soon</p>
          </div>
        ) : (
          postsData.map((post) => (
            <article key={post.id}>
              <div className="title-date">
                <h1>{post.title}</h1>
                <p className="date">({formatDate(post.date, post.id)})</p>
              </div>
              <p>{post.body}</p>
            </article>
          ))
        )}
      </main>
    </div>
  );
}

export default App;