import React from 'react';

const Whale = ({ color, width, height }) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={width} height={height} viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={color} stroke="none">
      <path d="M722 4208 c-6 -7 -22 -92 -37 -188 -35 -220 -32 -365 8 -472 l25 -66
        -121 -47 c-234 -91 -328 -157 -426 -298 -32 -46 -71 -86 -108 -112 -32 -23
        -59 -48 -61 -58 -5 -27 43 -62 120 -87 104 -32 327 -40 454 -15 166 33 318 99
        482 211 67 46 102 64 125 64 74 0 311 -156 512 -336 116 -104 211 -210 408
        -454 165 -203 299 -340 450 -460 59 -47 107 -92 107 -102 0 -29 -49 -154 -86
        -220 -56 -99 -134 -196 -269 -338 -100 -103 -125 -136 -125 -158 0 -65 107
        -117 205 -102 107 17 183 71 267 188 116 163 327 400 379 427 26 14 38 12 146
        -16 97 -26 144 -32 264 -36 164 -6 224 3 400 62 70 24 101 30 104 22 2 -7 8
        -34 15 -61 21 -93 70 -171 197 -315 73 -83 105 -141 133 -243 31 -114 61 -121
        126 -25 86 126 56 299 -79 452 -94 106 -149 204 -163 291 -6 38 -5 40 70 107
        42 37 129 132 195 210 189 225 312 318 536 402 124 46 175 124 126 193 -10 15
        -21 51 -23 80 -6 65 -30 100 -88 130 -55 27 -159 29 -300 5 -69 -11 -175 -17
        -385 -19 -324 -3 -373 -11 -523 -78 -42 -19 -88 -37 -102 -41 -56 -13 -559 58
        -705 100 -126 36 -257 104 -357 184 -108 86 -154 118 -249 170 -99 54 -107 53
        -106 -7 1 -56 -7 -72 -34 -72 -12 0 -111 42 -222 94 -235 110 -498 214 -629
        250 -50 14 -95 29 -99 33 -4 5 -17 42 -29 83 -57 201 -145 372 -248 482 -47
        50 -187 165 -229 187 -28 14 -38 14 -51 -1z" />
    </g>
  </svg>
);

export default Whale;
